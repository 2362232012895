<template>
<div class="h-auto pt-2">   


    <div class="h-12 w-full bg-gray-200">
        <div v-if="LicenseModel" class="flex justify-center">
               <v-icon class="mt-3 mr-2" size="24px" color="green">mdi-check-decagram-outline</v-icon> <h5 class="text-blackblue2 text-lg mt-3">  Has agregado el <span class="text-green-400 font-semibold">¡Plan {{LicenseModel.description}}!</span></h5>
        </div>
    </div>

    <div class="w-full bg-gray-50">
        <div class="sm:px-5 md:px-28">
                <h5 class="font-semibold sm:text-xl md:text-3xl text-blueblack px-5 pt-14">
                    ¡Ya casi! Termina tu pedido
                </h5>
                <h5 class="pt-6 pb-5 px-5 font-semibold sm:text-lg md:text-2xl text-blueblack">
                    1. Elige un período
                </h5>
                <span class="text-base text-navbar-text px-5">
                    Plan elegido: <span class="font-semibold text-blackblue2 text-base">Plan Avanzado</span>
                </span>
        </div>
        <div class="sm:px-5 md:px-36 pb-5">
            <div class="grid grid-cols-1 md:grid-cols-4 sm:gap-0 md:gap-5">
                <div class="mt-10">
                     <div class="flex justify-center pb-8 h-24">
                        <div v-show="oneMonth" class="bg-fucsia-thin bg-opacity-30 w-72  rounded-b-lg shadow-md" id="oneMonth">
                            <p  class="text-center text-base text-fucsia-dark mt-3">
                               <b> TIP: ¡Al elegir el plan de 48 meses ahorrarás MX$ 1,788.00!</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="h-80 w-72 bg-white shadow-lg hover:shadow-xl mb-10 rounded-lg cursor-pointer bg-cover" :style="`background-image: url('`+imgBgEntidadesURL+`')`" @click="hiddeElements('oneMonth')">
                            <div class="mt-6">
                                <div class="flex justify-center">
                                    <div class=" -mt-7">
                                        <v-checkbox
                                        class="-ml-24"
                                        v-model="oneMonth"
                                        color="#FE2B61"
                                        @click="hiddeElements('oneMonth')"
                                        ></v-checkbox>
                                    </div>
                                <h5 class="text-lg text-blueblack font-semibold" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">1 mes</h5>
                                </div>
                            </div>
                            <div class="text-center">
                                <h1 class="text-4xl text-blackblue2 font-bold mt-20">MX$ 149.00</h1>
                                <span class="text-navbar-text font-semibold">MXN / mes</span>
                            </div>
                            <div class="text-center mt-12 px-4">
                                <span class="text-navbar-text font-semibold text-sm">El plan se renovará por MX$ 149.00/mes el {{renewalOneMonth}}</span>
                            </div>    
                        </div>
                    </div>
                    <div class="text-center h-5">
                        <h5 v-show="oneMonth" class="text-pink-600 text-lg">Subtotal: <b>MX$ 149.00</b></h5>
                    </div>
                </div>
                <div class="sm:mt-5 md:mt-10">
                    <div class="flex justify-center pb-8 h-24">
                        <div v-show="twelveMonths" class="bg-fucsia-thin bg-opacity-30 w-72  rounded-b-lg shadow-md" id="twelveMonths">
                            <p class="text-center text-base text-fucsia-dark mt-3 ">
                                <b>TIP: ¡Al elegir este plan ahorrarás MX$ 268.02!</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="h-80 w-72 bg-white shadow-lg hover:shadow-xl mb-10 rounded-lg cursor-pointer bg-cover" :style="`background-image: url('`+imgBgEntidadesURL+`')`" @click="hiddeElements('twelveMonths')">
                            <div class="flex justify-center">
                                <div class="bg-gray-300 h-9 w-36 -mt-5 rounded-xl text-center shadow-md">
                                    <div class="pt-2">
                                        <h6 class="font-semibold text-sm text-blackblue2" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">AHORRA 15%</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="flex justify-center">
                                    <div class=" -mt-7">
                                        <v-checkbox
                                        class="-ml-20"
                                        v-model="twelveMonths"
                                        color="#FE2B61"
                                        @click="hiddeElements('twelveMonths')"
                                        ></v-checkbox>
                                    </div>
                                    <h5 class="text-lg text-blueblack font-semibold" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">12 meses</h5>
                                </div>
                            </div>
                            <div class="text-center">
                                <div class=" mt-14">
                                    <span class="line-through text-base text-pink-600">MX$ 149.00</span>
                                    <h1 class="text-4xl text-blackblue2 font-bold">MX$ 126.65</h1>
                                    <span class="text-navbar-text font-semibold">MXN / mes</span>
                                </div>
                            </div>
                            <div class="text-center mt-12 px-4">
                                <span class="text-navbar-text font-semibold text-sm">El plan se renovará por MX$ 149.00/mes el {{renewalTwelveMonth}}</span>
                            </div>                            
                        </div>
                    </div>                    
                    <div class="text-center h-5">
                        <h5 v-show="twelveMonths" class="text-pink-600 text-lg">Subtotal: <b>MX$ 149.00</b></h5>
                    </div>
                </div>
                <div class="sm:mt-5 md:mt-10">
                    <div class="flex justify-center pb-8 h-24">
                        <div v-show="twentyfourMonths" class="bg-fucsia-thin bg-opacity-30 w-72  rounded-b-lg shadow-md" id="twentyfourMonths">
                            <p class="text-center text-base text-fucsia-dark mt-3">
                                <b> TIP: ¡Al elegir este plan ahorrarás MX$ 643.68!</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="h-80 w-72 bg-white shadow-lg hover:shadow-xl mb-10 rounded-lg cursor-pointer bg-cover" :style="`background-image: url('`+imgBgEntidadesURL+`')`" @click="hiddeElements('twentyfourMonths')">
                             <div class="flex justify-center">
                                <div class="bg-gray-300 h-9 w-36 -mt-5 rounded-xl text-center shadow-md">
                                    <div class="pt-2">
                                        <h6 class="font-semibold text-sm text-blackblue2" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">AHORRA 18%</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="flex justify-center">
                                    <div class=" -mt-7">
                                        <v-checkbox
                                        class="-ml-20"
                                        v-model="twentyfourMonths"
                                        color="#FE2B61"
                                        @click="hiddeElements('twentyfourMonths')"
                                        ></v-checkbox>
                                    </div>
                                    <h5 class="text-lg text-blueblack font-semibold" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">24 meses</h5>
                                </div>
                            </div>
                            <div class="text-center">
                                <div class=" mt-14">
                                    <span class="line-through text-base text-pink-600">MX$ 149.00</span>
                                    <h1 class="text-4xl text-blackblue2 font-bold">MX$ 122.18</h1>
                                    <span class="text-navbar-text font-semibold">MXN / mes</span>
                                </div>
                            </div>
                            <div class="text-center mt-12 px-4">
                                <span class="text-navbar-text font-semibold text-sm">El plan se renovará por MX$ 149.00/mes el {{renewaltwentyfourMonths}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center h-5">
                        <h5 v-show="twentyfourMonths" class="text-pink-600 text-lg">Subtotal: <b>MX$ 149.00</b></h5>
                    </div>                    
                </div>
                <div class="sm:mt-5 md:mt-10">
                     <div class="flex justify-center pb-8 h-24">
                        <div v-show="fortyeightMonths" class="bg-fucsia-thin bg-opacity-30 w-72  rounded-b-lg shadow-md" id="fortyeightMonths">
                            <p   class="text-center text-base text-fucsia-dark mt-3 ">
                                 <b>TIP: ¡Al elegir este plan ahorrarás MX$ 1,788.00!</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="h-80 w-72 bg-white shadow-lg hover:shadow-xl mb-10 rounded-lg cursor-pointer bg-cover" :style="`background-image: url('`+imgBgEntidadesURL+`')`" @click="hiddeElements('fortyeightMonths')">
                            <div class="flex justify-center">
                                <div class="bg-fucsia-lighter h-9 w-36 -mt-5 rounded-xl text-center shadow-md">
                                    <div class="pt-2">
                                        <h6 class="font-semibold text-sm text-white" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">AHORRA 25%</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="flex justify-center">
                                    <div class=" -mt-7">
                                        <v-checkbox
                                        class="-ml-20"
                                        v-model="fortyeightMonths"
                                        color="#FE2B61"
                                        @click="hiddeElements('fortyeightMonths')"
                                        ></v-checkbox>
                                    </div>
                                    <h5 class="text-lg text-blueblack font-semibold" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif;">48 meses</h5>
                                </div>                                                                
                            </div>
                            <div class="text-center">
                                <div class=" mt-14">
                                    <span class="line-through text-base text-pink-600">MX$ 149.00</span>
                                    <h1 class="text-4xl text-blackblue2 font-bold">MX$ 111.75</h1>
                                    <span class="text-navbar-text font-semibold">MXN / mes</span>
                                </div>
                            </div>
                            <div class="text-center mt-12 px-4">
                                <span class="text-navbar-text font-semibold text-sm">El plan se renovará por MX$ 149.00/mes el {{renewalFortyEightMonths}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mb-5 h-5">
                        <h5 v-show="fortyeightMonths" class="text-pink-600 text-lg">Subtotal: <b>MX$ 149.00</b></h5>
                    </div>                    
                </div>
            </div>
        </div>

        <div v-if="!isauth" class="sm:px-5 md:px-28">
            <h5 class="pt-6 pb-5 px-5 font-semibold sm:text-lg md:text-2xl text-blueblack">
                2. Crea tu cuenta
            </h5>

        <div class="w-128">
            <label class="text-base text-navbar-text">Correo electrónico que usarás para iniciar sesión</label>
            <input  class="input-full-fucsia"  placeholder="Ingrese tu correo" />
        </div>

        </div>

        <div class="sm:px-5 md:px-28">
            <h5 v-if="!isauth" class="pt-6 pb-5 px-5 font-semibold sm:text-lg md:text-2xl text-blueblack">
                3. Realizar pago
            </h5>
            <h5 v-else class="pt-6 pb-5 px-5 font-semibold sm:text-lg md:text-2xl text-blueblack">
                2. Realizar pago
            </h5>

            <div>
                <div v-if="!paidFor">
                <h1>Buy this Lamp - ${{ product.price }} OBO</h1>

                <p>{{ product.description }}</p>

                </div>

                <div v-if="paidFor">
                <h1>Noice, you bought a beautiful lamp!</h1>
                </div>

                <div ref="paypal"></div>
            </div>

        </div>

    </div>

</div>
    
</template>

<script>
import { mapGetters } from "vuex";
import { LicenceService } from '../../services/HSuite/Context/hsuite-core-context';

export default {
     
    name : "prices",

    data(){
        return {
        /*variable show / hidde divs*/
        oneMonth:true,
        twelveMonths:false,
        twentyfourMonths:false,
        fortyeightMonths:false,
        /*renewal*/
        renewalOneMonth:'',
        renewalTwelveMonth:'',
        renewaltwentyfourMonths:'',
        renewalFortyEightMonths:'',
        LicenseModel : null,


        imgBgEntidadesURL:require('@/assets/home/bgfondocards.png'),

        loaded: false,
        paidFor: false,
        product: {
            price: 136.58,
            description: "Licencia de rebyu",
        }

        }
    },
    computed: {
        ...mapGetters({
            isauth: 'auth/isAuth'
        })
    },

    async mounted() {


    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=Af0a_Qe82uOnrg---DN4jm5ALoDpgAeqmfpY5TNdU6zpt_9p4WsoEddGeuIem25ePy3g_IIZl1w3FCa8&currency=MXN&locale=es_MX";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);



    },

    created(){
        // this.calculateRenewal()
        this.Init();
    },
    methods:{

        hiddeElements(id){
            switch(id)
            {
                case 'oneMonth':
                    this.product.price = 149;
                    this.oneMonth = true; 
                    this.twelveMonths = false;
                    this.twentyfourMonths = false;
                    this.fortyeightMonths = false;
                break;

                case'twelveMonths':
                this.product.price =  1519.8;
                    this.oneMonth = false;
                    this.twelveMonths = true; 
                    this.twentyfourMonths = false;
                    this.fortyeightMonths = false;
                break;

                case'twentyfourMonths':
                this.product.price = 2932.32;
                    this.oneMonth = false;
                    this.twelveMonths = false;
                    this.twentyfourMonths = true; 
                    this.fortyeightMonths = false;                
                break;

                case'fortyeightMonths':
                this.product.price = 5364;
                    this.oneMonth = false;
                    this.twelveMonths = false;
                    this.twentyfourMonths = false;      
                    this.fortyeightMonths = true;            
                break;
            }

        },

        Init(){
            this.calculateRenewal();
            this.getInfoLicense();
        },

        getInfoLicense : async function(){

            if( this.$route.params.id ){
                let response = await LicenceService.getbyId(this.$route.params.id);
                if(response.success) {
                    this.LicenseModel = response.data;
                // this.ListaUltimos.push( this.ListaUltimos[0], this.ListaUltimos[3])
                    
                }else {
                    console.log(response.message);
                }
            }
        },

        calculateRenewal(){
            this.renewalOneMonth = this.addMonthsL(1)
            this.renewalTwelveMonth = this.addMonthsL(12)
            this.renewaltwentyfourMonths = this.addMonthsL(24)
            this.renewalFortyEightMonths = this.addMonthsL(48)
        },

        addMonthsL(months){
            var date = new Date();
            var d = date.getDate();
            date.setMonth(date.getMonth() + 1 + months);
            if (date.getDate() != d) {
                date.setDate(0);
            }
             return date.getDate()+'/'+date.getMonth()+'/'+date.getFullYear();
        },

        setLoaded: function() {
        this.loaded = true;
        window.paypal
            .Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                purchase_units: [
                    {
                    description: this.product.description,
                    amount: {
                        currency_code: "MXN",
                        value: this.product.price
                    }
                    }
                ]
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                this.paidFor = true;
                console.log(order);
            },
            onError: err => {
                console.log(err);
            }
            })
            .render(this.$refs.paypal);
        }
  
    }

}
</script>
